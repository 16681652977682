import React, { useEffect, useState } from 'react';
import { createWorker } from 'tesseract.js';
import './App.css';

function setSpeech() {
  return new Promise(function (resolve, reject) {
    let synth = window.speechSynthesis;
    let id;

    id = setInterval(() => {
      if (synth.getVoices().length != 0) {
        resolve(synth.getVoices());
        clearInterval(id);
      }
    }, 10);
  });
}

function App() {
  const [ocr, setOcr] = useState('Recognizing...');
  const [listSentences, setListSentences] = useState([]);
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [rate, setRate] = useState(1);
  const [msg, setMsg] = useState();
  const [itv, setitv] = useState(null);
  // msg.lang = 'vi-VN';
  useEffect(() => {
    setSpeech().then((voices) => {
      console.log('voices: ', voices);
      const temp = new SpeechSynthesisUtterance();
      temp.lang = 'vi-VN';
      temp.volume = 1;
      setMsg(temp);
    });
  }, []);

  // msg.voice =
  // const voices = window.speechSynthesis.getVoices();
  // msg.text = "xin chào các bạn";
  // window.speechSynthesis.speak(msg);
  // console.log('voices: ', voices);

  const handleChangeFile = async (e) => {
    let file = e.target.files[0];
    const worker = createWorker({
      logger: (m) => console.log(m),
    });
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.addEventListener('loadend', async (event) => {
      console.log('#1 ');
      await worker.load();
      await worker.loadLanguage('vie');
      await worker.initialize('vie');
      console.log('#2 ');
      const {
        data: { text },
      } = await worker.recognize(reader.result);
      console.log('text: ', text);
      setOcr(text);
    });
  };

  const handleOnChangeText = (e) => {
    setOcr(e.target.value);
  };

  const handleClickBreakSentences = () => {
    const list = ocr.split('\n');
    console.log('list: ', list);
    setListSentences(list);
    setCurrentSentenceIndex(0);
  };
  const readSentence = (sentence) => {
    msg.rate = rate;
    msg.text = sentence;
    window.speechSynthesis.speak(msg);
  };

  const handleClickSentence = (sentence) => {
    // alert(sentence);
    readSentence(sentence);
    if (itv) clearInterval(itv);
    const len = sentence.length;
    const id = setInterval(() => {
      readSentence(sentence);
    }, (5000 * len) / 10);
    setitv(id);
    setCurrentSentenceIndex(listSentences.findIndex((s) => s === sentence));
    console.log('rate: ', rate);
  };

  const handleChangeRate = (e) => {
    // msg.rate = e.target.value;
    setRate(e.target.value);
  };

  const handleReadCurrentSentence = () => {
    if (listSentences.length === 0) {
      return alert('Please break sentences first 😏');
    }
    if (currentSentenceIndex != null) {
      const line = listSentences[currentSentenceIndex];
      const len = line.length;
      readSentence(line);
      if (itv) clearInterval(itv);
      const id = setInterval(() => {
        readSentence(line);
      }, (5000 * len) / 10);
      setitv(id);
    }
  };

  const handleReadNextSentence = () => {
    if (listSentences.length === 0)
      return alert('Please break sentences first 🙄');
    if (itv) clearInterval(itv);
    if (currentSentenceIndex != null) {
      setCurrentSentenceIndex((prev) => prev + 1);
      const line = listSentences[currentSentenceIndex + 1];
      const len = line.length;
      readSentence(line);
      const id = setInterval(() => {
        readSentence(line);
      }, (5000 * len) / 10);
      setitv(id);
    } else {
      setCurrentSentenceIndex(0);
      const line = listSentences[0];
      const len = line.length;
      readSentence(line);
      const id = setInterval(() => {
        readSentence(line);
      }, (5000 * len) / 10);
      setitv(id);
    }
  };

  const handleStop = () => {
    if (itv) {
      clearInterval(itv);
      setitv(null);
      window.speechSynthesis.cancel();
    }
  };
  return (
    <div className='App'>
      <div id='rate-control'>
        <label for='rate'>Rate:</label>
        <input
          type='range'
          min='0.1'
          max='2'
          value={rate}
          step='0.1'
          id='rate'
          onChange={handleChangeRate}
        />
      </div>
      <div className={``}>
        <button onClick={handleReadCurrentSentence}>
          📢 Read Current Sentence
        </button>
        <button onClick={handleReadNextSentence}>⏭Read Next Sentence</button>
        <button onClick={handleStop}>❌Stop speaking</button>
      </div>
      <input
        type='file'
        id='input_image'
        accept='image/*'
        onChange={handleChangeFile}
      />
      <p>Change text before Read outloud</p>
      <textarea value={ocr} onChange={handleOnChangeText} />
      <button onClick={handleClickBreakSentences}>Break Sentences</button>
      <div className={``}>
        {listSentences.map((sentence, index) => (
          <>
            <button
              className={`sentence${
                index === currentSentenceIndex ? ' highlighted-sentence' : ''
              }`}
              onClick={() => handleClickSentence(sentence)}
            >
              {sentence}
            </button>
          </>
        ))}
      </div>
    </div>
  );
}

export default App;
